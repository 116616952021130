import { ScrollingModule } from '@angular/cdk/scrolling';
import { ChangeDetectionStrategy, Component, HostBinding, Signal } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatMenuModule } from '@angular/material/menu';
import { RouterLink } from '@angular/router';

import { HeaderElementDirective } from '@core/shared/util';
import { OverviewModule, RootModuleNavComponent, SpinnerComponent } from '@core/ui';
import { AddressesFacade } from '@mp/organization-master-data/addresses/data-access';
import { AddressListItem } from '@mp/organization-master-data/addresses/domain';

import { AddressChipContentPipe } from './address-chip-content.pipe';

@Component({
  selector: 'mpomd-addresses-list',
  standalone: true,
  templateUrl: './addresses-list.component.html',
  styleUrl: './addresses-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RouterLink,

    MatMenuModule,
    MatChipsModule,

    ScrollingModule,
    OverviewModule,
    SpinnerComponent,
    HeaderElementDirective,
    RootModuleNavComponent,
    AddressChipContentPipe,
  ],
})
export class AddressesListComponent {
  @HostBinding('class') className = 'mpomd-addresses-list';
  readonly addresses: Signal<AddressListItem[]> = this.facade.addresses;
  readonly addressesLoaded: Signal<boolean> = this.facade.addressesLoaded;

  constructor(private readonly facade: AddressesFacade) {
    this.fetchAddresses();
  }

  fetchAddresses(searchTerm?: string): void {
    this.facade.fetchAddresses(searchTerm);
  }

  trackByFn(_index: number, { addressId }: AddressListItem): string {
    return addressId;
  }
}
