import { Pipe, PipeTransform } from '@angular/core';

import { AddressType, addressTypeDescriptions } from '@mp/organization-master-data/addresses/domain';

@Pipe({
  name: 'mpomdAddressChipContent',
  standalone: true,
})
export class AddressChipContentPipe implements PipeTransform {
  transform(values: AddressType[] | null): string | null {
    if (!values) {
      return null;
    }
    const hasDefaultDeliveryAddress = values.includes(AddressType.DefaultDeliveryAddress);
    const hasDefaultBillingAddress = values.includes(AddressType.DefaultBillingAddress);
    const hasMainAddress = values.includes(AddressType.MainAddress);

    if (!hasDefaultDeliveryAddress && !hasDefaultBillingAddress && !hasMainAddress) {
      return null;
    }

    let returnValue = null;
    if (hasMainAddress) {
      returnValue = addressTypeDescriptions[AddressType.MainAddress];
    } else if (hasDefaultDeliveryAddress) {
      returnValue = addressTypeDescriptions[AddressType.DefaultDeliveryAddress];
    } else if (hasDefaultBillingAddress) {
      returnValue = addressTypeDescriptions[AddressType.DefaultBillingAddress];
    }

    const additionalItemsCount = values.length - 1;

    if (returnValue && additionalItemsCount >= 1) {
      returnValue += ` +${additionalItemsCount}`;
    }

    return returnValue;
  }
}
