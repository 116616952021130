<ng-container *mpHeaderElement="'left'">
  <mp-root-module-nav label="Adressen" />
</ng-container>

<mp-overview moduleTitle="Adressen" [resultCount]="addresses().length" (search)="fetchAddresses($event)">
  <button mat-menu-item routerLink="create">Neu</button>

  @if (addressesLoaded()) {
    <cdk-virtual-scroll-viewport itemSize="100">
      <mp-overview-item *cdkVirtualFor="let address of addresses(); trackBy: trackByFn" [path]="address.addressId">
        <div class="title">
          <h2 class="title__street-name truncate">{{ address.street }}</h2>
          @if (address.addressTypes | mpomdAddressChipContent; as addressTypes) {
            <mat-chip [removable]="false" class="chip-item">
              {{ addressTypes }}
            </mat-chip>
          }
        </div>
        <div>{{ address.zipCode }}, {{ address.city }}</div>
      </mp-overview-item>
    </cdk-virtual-scroll-viewport>
  } @else {
    <mp-spinner />
  }
</mp-overview>
